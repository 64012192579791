import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {useOutlet} from 'reconnect.js';
import Anchor from '../../Widgets/Anchor';
import Block from '../../Widgets/Block';
import Alert from '../../Widgets/Alert';
import {ErrUpgrade, errorHandler} from '../../errors';
import {MONTHLY_TYPE} from '../../dictionary';
const appConfig = require('../../data.json');

export default function EnterpriseBlock({
  record,
  entData = {},
  redirect = true,
}) {
  let {
    ent_name = '',
    gui_number = '',
    ent_tel = '',
    tel_ext = '',
    ent_zip_code = '',
    ent_city = null,
    ent_district = null,
    ent_address = '',
    industry_type = '',
    contact_name = '',
    fax = '',
    license_image = '',
  } = entData;
  const [actions] = useOutlet('actions');
  const [loading, setLoading] = useOutlet('loading');
  gui_number = gui_number || record.uni_numbers;

  const readFile = useCallback(async (key) => {
    try {
      setLoading(true);

      if (!key) {
        throw new ErrUpgrade('找不到檔名');
      }
      let {url} = await actions.getPrivateFile(key);
      window.open(url, '_blank');
    } catch (err) {
      errorHandler(err);
    }
    setLoading(false);
  });

  return (
    <Block>
      <h2>企業資訊</h2>
      <div className="divider" />
      <div>
        {(!gui_number || !ent_name) && (
          <Alert color={appConfig.colors.error} style={{marginBottom: 15}}>
            請填入統編及抬頭避免發生錯誤！
          </Alert>
        )}
        <div className="row">
          <h4>會員編號</h4>
          {redirect ? (
            <Anchor to={`/member/?id=${record.id}`}>{record.id}</Anchor>
          ) : (
            <div>{record.id}</div>
          )}
        </div>
        <div className="row">
          <h4>公司抬頭</h4>
          <div>{ent_name}</div>
        </div>
        <div className="row">
          <h4>公司統編</h4>
          <div>{gui_number}</div>
        </div>
        <div className="row">
          <h4>公司電話</h4>
          <div>{ent_tel}</div>
        </div>
        <div className="row">
          <h4>公司分機</h4>
          <div>{tel_ext}</div>
        </div>
        <div className="row">
          <h4>公司地址</h4>
          <div>
            {ent_zip_code} {ent_city} {ent_district} {ent_address}
          </div>
        </div>
        {(record.user_type === 'monthly' ||
          record.user_type === 'ent_vip_monthly') && (
          <Fragment>
            <div className="row">
              <h4>聯絡人名稱</h4>
              <div>{contact_name}</div>
            </div>
            <div className="row">
              <h4>產業類型</h4>
              <div>{industry_type}</div>
            </div>
            <div className="row">
              <h4>傳真</h4>
              <div>{fax}</div>
            </div>
            <div className="row">
              <h4>營業執照文件</h4>
              <Anchor type="button" onClick={() => readFile(license_image)}>
                {license_image || '--'}
              </Anchor>
            </div>
            <div className="row">
              <h4>月結額度</h4>
              <div>{record.monthly_quota} 元</div>
            </div>
            <div className="row">
              <h4>當月已使用額度</h4>
              <div>{record.monthly_used_quota} 元</div>
            </div>
            <div className="row">
              <h4>月結結算日</h4>
              <div>{MONTHLY_TYPE[record.monthly_type] || '--'} </div>
            </div>
            <div className="row">
              <h4>月結繳費緩衝期</h4>
              <div>{record.monthly_pay_buffer} 天</div>
            </div>
          </Fragment>
        )}
      </div>
    </Block>
  );
}
